.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 40rem;
  height: fit-content;
  border-radius: 20px;
}

.card img {
  width: 100%;
  height: 100%;
  user-select: none;
  border-radius: 20px;
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1340px) {
  .card {
    width: 35rem;
  }
}
@media (max-width: 1260px) {
  .card {
    width: 32rem;
  }
}
@media (max-width: 725px) {
  .card {
    width: 30rem;
  }
}
@media (max-width: 650px) {
  .card {
    width: 26rem;
  }
}
@media (max-width: 550px) {
  .card {
    width: 24rem;
  }
}

@media (max-width: 500px) {
  .card {
    width: 22rem;
  }
}

@media (max-width: 460px) {
  .card {
    width: 20rem;
  }
}

@media (max-width: 410px) {
  .card {
    width: 18rem;
  }
}