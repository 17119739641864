.works__content {
  display: flex;
  justify-content: space-between;

  margin: 100px 0;

  .works__main-info {
    user-select: none;
    .works__title {
      font-size: 60px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .works__description {
      font-weight: 400;
      font-size: 24px;
      max-width: 450px;
      line-height: 150%;
    }
  }
  .works__carosel {
    width: 70%;
  }
}

@media (max-width: 1390px) {
  .content__width {
    .works__content {
      .works__carosel {
        width: 60%;
      }
    }
  }
}

@media (max-width: 1260px) {
  .content__width {
    .works__content {
      .works__carosel {
        width: 50%;
      }
    }
  }
}
@media (max-width: 1140px) {
  .works__content {
    .works__main-info {
      .works__title {
        font-size: 55px;
      }
      .works__description {
        max-width: 420px;
      }
    }
  }
}
@media (max-width: 1140px) {
  .works__content {
    .works__carosel {
      width: 55%;
    }
  }
}
@media (max-width: 1110px) {
  .works__content {
    margin-top: 120px;

    flex-direction: column;
    align-items: center;
    .works__main-info {
      .works__title {
        text-align: center;
      }
      .works__description {
        max-width: 600px;
        margin-bottom: 100px;
      }
    }
  }
}

@media (max-width: 725px) {
  .works__content {
    .works__main-info {
      .works__title {
        font-size: 50px;
        line-height: 50px;
      }
      .works__description {
        max-width: 500px;
        margin-bottom: 70px;
      }
    }
  }
}
@media (max-width: 650px) {
  .works__content {
    .works__main-info {
      .works__title {
        font-size: 48px;
      }
      .works__description {
        max-width: 460px;
        margin-bottom: 70px;
      }
    }
  }
}
@media (max-width: 550px) {
  .works__content {
    margin-bottom: 50px;
    .works__main-info {
      .works__title {
        font-size: 45px;
      }
      .works__description {
        max-width: 410px;
        font-size: 22px;
        margin-bottom: 40px;
      }
    }
  }
}
@media (max-width: 500px) {
  .works__content {
    .works__main-info {
      .works__title {
        font-size: 45px;
      }
      .works__description {
        max-width: 350px;
        font-size: 19px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 450px) {
  .works__content {
    .works__main-info {
      .works__title {
        font-size: 40px;
        line-height: 108.9%;
      }
      .works__description {
        max-width: 326px;
        font-size: 17px;
        margin-bottom: 10px;
      }
    }
  }
}
