.carousel__config {
  height: 500px;
  width: 20%;
  margin: 0 auto;
}

@media (max-width: 1110px) {
  .carousel__config {
    width: 50%;
  }
}

@media (max-width: 550px) {
  .carousel__config {
    height: 450px;
    width: 60%;
  }
}

@media (max-width: 500px) {
    .carousel__config {
      height: 400px;
      width: 80%;
    }
  }
  
