.burger{
  display: none;
}
.header {
  position: relative;
  z-index: 20;

  svg {
    height: 70px;
    position: absolute;
    top: 20px;
  }
  @media (max-width: 1110px) {
    svg {
      height: 55px;
      width: max-content;
    }
  }

  .nav {
    padding: 30px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      display: flex;

      a {
        user-select: none;
        margin-right: 74px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-decoration: none;
      }
      a:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 870px) {
  .header {
    display: none;
  }
  .burger {
    display: block;
    height: 50px;
    position: absolute;
    position: fixed;
    z-index: 20;
    top: 40px;
    left: 40px;
  }
}
