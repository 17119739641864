@import url("../source/fonts/stylesheet.css");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  color: #e9e9e9;
}

body {
  background: linear-gradient(90deg, #111111 0%, #414040 100%);
  background-color: #111111;
  overflow-y: overlay;
}
.wrapper {
  overflow: hidden;
  position: relative;
  overflow-y: hidden;
  // height: 88vh;
  .shadow__top {
    z-index: 1;
    filter: blur(5px);
    position: absolute;
    top: -10px;
    right: -5px;
    background: linear-gradient(
      180deg,
      rgba(11, 11, 11, 0.9) 24%,
      rgba(0, 0, 0, 0) 85%
    );
    width: 105vw;
    height: 134px;
    overflow: hidden;
  }
  .shadow__bottom {
    z-index: 1;
    filter: blur(5px);
    position: absolute;
    bottom: -10px;
    right: -5px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 24%,
      rgba(11, 11, 11, 0.9) 85%
    );
    width: 105vw;
    height: 140px;
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  width: calc(100vw - 120px);
  padding: 0 15px;
  min-height: 100vh;
  position: relative;
}

.home__image {
  position: absolute;
  bottom: 0;
  right: -60px;
  width: 80vw;
  height: 100%;
  z-index: 0;
  background-image: url("../source/image/bg/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-position: right, bottom;
}
.content__width {
  max-width: 1440px;
  margin: 0 auto;
}

.burger__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 35px 0;
  position: absolute;
  z-index: 50;
  width: 400px;
  min-height: 100vh;
  background-color: #111111;
  position: fixed;
  left: -100%;
  transition: left 0.2s ease-in-out;
  .burger__arrow {
    position: absolute;
    top: 40px;
    right: 40px;
  }
  .burger__logo {
    svg {
      height: 114px;
      width: auto;
    }
    margin-bottom: 80px;
  }
  .burger__list {
    width: 100%;
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 25px;
      li {
        padding: 24px 0;
        border-top: 1px solid #383838;
      }
      .burger__border {
        border-bottom: 1px solid #383838;
      }
      &:active {
        list-style: none;
      }
    }
  }
}
.active {
  left: 0;
}

@media (max-width: 1110px) {
  .container {
    width: calc(100vw - 90px);
  }
}
@media (max-width: 950px) {
  .container {
    width: calc(100vw - 60px);
  }
}

@media (max-width: 870px) {
  body {
    background: #111111;
  }

  .home__image {
    opacity: 0.3;
    width: 100vw;
    position: fixed;
    top:0;
    

    filter: blur(4.5px);
    right: 0px;
    background-position: top;
  }
  .container {
    padding: 0;
    width: 100vw;
  }
  .shadow__top,
  .shadow__bottom {
    display: none;
  }
}
@media (max-width: 600px) {
  .burger__menu {
    width: 100vw;
  }
}

@media (max-width: 375px) {
  .burger__menu {
    .burger__logo {
      svg {
        height: 90px;
      }
      margin-bottom: 40px;
    }
    .burger__list {
      a {
        li {
          padding: 18px;
        }
      }
    }
  }
}
