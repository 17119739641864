.about__content {
  padding-top: 50px;
  max-width: 1080px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  user-select: none;

  .content__card {
    h3 {
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 40px;
    }
    .about__title {
      text-transform: uppercase;
      text-align: center;
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 60px;
      font-weight: 600;
      display: none;
    }
  }
}

.cards {
  display: flex;

  max-width: max-content;
  padding-bottom: 10px;

  .card {
    margin-right: 40px;
    background: linear-gradient(
      145.22deg,
      rgba(158, 156, 156, 0.1) 0%,
      #656565 0.01%,
      rgba(61, 61, 61, 0.4) 100%
    );
    padding: 36px 12px 36px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.25);

    svg {
      max-height: 100px;

      margin-bottom: 30px;
    }
    .size {
      width: 100px;
      height: 100px;
    }
    .size1 {
      width: 90px;
      height: 100px;
    }

    p {
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      text-align: center;
      width: 190px;
      line-height: 20px;
    }
  }
}

.content__gift {
  margin: 40px 0;
  padding: 40px 0;
  position: relative;

  &:before {
    width: 80%;
    height: 1px;
    top: 0;

    content: "";
    background-color: #383838;
    position: absolute;
  }
  &:after {
    width: 80%;
    height: 1px;
    bottom: 0;
    content: "";
    background-color: #383838;
    position: absolute;
  }
  .gift__title {
    font-weight: 500;
    font-size: 30px;
    line-height: 43px;
    margin-bottom: 20px;
  }

  .gift__list {
    margin-left: 60px;

    li {
      font-weight: 400;
      font-size: 22px;
      line-height: 200%;
      position: relative;
      list-style: outside;
    }
  }
}
.info {
  font-weight: 500;
  font-size: 25px;
  padding: 15px 0;
}
.footer__text {
  position: relative;

  z-index: 10;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    user-select: none;
    text-align: center;
    color: rgba(255, 255, 255, 0.53);
    font-weight: 400;
    font-size: 20px;
  }
}

@media (max-width: 1110px) {
  .card__scroll {
    overflow-x: scroll;
  }
}
@media (max-width: 870px) {
  .about__content {
    padding-top: 120px;

    .content__card {
      h3 {
        display: none;
      }
      .about__title {
        padding-left: 0;

        display: block;
      }
      .cards {
        margin-left: 30px;
      }
    }
  }
  .content__position {
    padding: 0 50px;
  }
  .content__gift {
    .gift__list {
      margin-left: 20px;
    }
  }

  .footer__text {
    p {
      font-size: 18px;
    }
  }
}
@media (max-width: 755px) {
  .cards {
    .card {
      margin-right: 35px;
      padding: 30px 10px;
      svg {
        height: 80px;
        width: 80px;
      }
      p {
        width: 180px;
        font-size: 16px;
      }
    }
  }
  .content__gift {
    .gift__title {
      line-height: 35px;
      text-align: center;
    }
    &::before,
    &::after {
      width: 90%;
    }
  }
  .footer__text {
    padding: 0 50px;
  }
}

@media (max-width: 680px) {
  .about__content {
    .content__card {
      .about__title {
        font-size: 55px;
      }
    }
  }
  .content__gift {
    .gift__list {
      li {
        margin-bottom: 5px;
        line-height: 145%;
      }
    }
  }
  .info {
    font-size: 23px;
  }
}
@media(max-width:600px){
  .about__content{
    .content__card{
      .about__title{
        font-size: 50px;
      }
    }
  }
}
@media(max-width:583px){
  .content__gift{
    .gift__list{
      li{
        font-size: 20px;
      }
    }
  }
  .content__position{
    padding: 0 30px;
  }
}
@media(max-width:500px){
  .about__content{
    .content__card{
      .cards{
        .card{
          margin-right: 15px;
          padding: 21px 8px;
          svg{
            margin-bottom: 20px;
          }
          p{
            width: 150px;
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
      .about__title{
        font-size: 40px;
        line-height: 40px;
      }
    }
  }
  .content__gift{
    .gift__title{
      font-size: 26px;
    }
    .gift__list{
      font-size: 20px;
    }
  }
  .info{
    font-size: 20px;
  }
  .footer__text{
    padding: 20px;
    font-size: 15px;
  }
  .content__gift {
    padding: 22px 0;
    margin-bottom: 22px;
    &::before,
    &::after {
      width: 100%;
    }
  }
}