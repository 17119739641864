.main {
  position: absolute;
  margin-top: -50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 30%;
  height: 600px;
  box-shadow: 0px 0px 79px 140px #121212;
  min-height: 100%;
  svg {
    margin-bottom: 25px;
  }
  &__content {
    h1 {
      font-weight: 600;
      font-size: 45px;
      letter-spacing: 2px;
      line-height: 48px;
      margin-bottom: 15px;
    }
    .content__desc {
      font-size: 20px;
      font-weight: 300;
      line-height: 37px;
      margin-bottom: 90px;
    }
    .button {
      text-decoration: none;
      transition: 0.2s;
      max-width: max-content;
      display: flex;
      align-items: center;
      padding: 13px 21px;
      background-color: #7c5a34;
      border-radius: 35px;
      border: none;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        margin: 0 10px 0 0;
      }
      span {
        user-select: none;
        font-weight: 600;
        font-size: 17px;
      }
      &:hover {
        background-color: #825f36;
      }
      &:active {
        background-color: #72532f;
      }
    }
  }
}

@media (max-width: 1110px) {
  .main {
    width: 35%;
    margin-top: -70px;
    svg{
      height: 160px;
      margin-bottom: 10px;
    }
    &__content {
      h1 {
        font-size:40px;

      }
      .content__desc{
        font-size: 18px;
        margin-bottom: 60px;
      }
    }
  }
}
@media(max-width:870px){
  .main {
    width: 100%;
    margin-top: 0px;
    background-color: inherit;
    position: fixed;

    box-shadow: none;
    
    &__content {
      h1 {

        line-height: 38px;
        letter-spacing: 1px;

      }
     
    }
  }

}
@media (max-width: 375px) {
  .main{
    svg{
      height: 140px;
      
    }
    &__content{
        h1{
          letter-spacing: 0.1px;
        }
        .content__desc{
          margin-bottom: 50px;
        }
      }
  }
}
