.contacts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
  position: relative;
  z-index: 10;

  &__content {
    .title__name {
      padding-right: 30px;
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 30px;
      user-select: none;
      span {
        font-weight: 600;
        font-size: 55px;
      }
    }
    .content__description {
      font-weight: 300;
      font-size: 25px;
      opacity: 0.9;
      margin-bottom: 50px;
      user-select: none;
      padding-right: 30px;
    }
  }

  &__list {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      a {
        display: block;
        font-weight: 500;
        font-size: 20px;
        text-decoration: none;
      }
      .size__call {
        font-size: 24px;
      }

      svg {
        width: 35px;
        height: 35px;
        margin-right: 20px;
      }
      .list__locate {
        font-weight: 500;
        font-size: 20px;
      }
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
}

.maps {
  width: 550px;
  height: 550px;
  background-color: #d7d7d7;
  border-radius: 40px;
}

.mapConfig {
  width: 550px;
  height: 550px;
}
.ymaps-2-1-79-map {
  border-radius: 40px;
}
.ymaps-2-1-79-inner-panes {
  border-radius: 40px;
}

@media (max-width: 1360px) {
  .title__name {
    line-height: 55px;
  }
}

@media (max-width: 1110px) {
  .contacts {
    flex-direction: column;

    &__content {
      margin-bottom: 100px;
      .title__name {
        padding-right: 0;
      }
      .content__description {
        padding-right: 0;
      }
    }
  }

  .maps {
    width: 660px;
    height: 600px;
  }

  .mapConfig {
    width: 660px;
    height: 600px;
  }
}

@media (max-width: 870px) {
  .contacts {
    margin-top: 150px;
  }
}

@media (max-width: 790px) {
  .contacts {
    margin-top: 120px;
  }
  .contacts__content {
    .title__name {
      span {
        font-size: 52px;
      }
      font-size: 40px;
    }
  }
  .maps {
    width: 500px;
    height: 500px;
  }

  .mapConfig {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 620px) {
  .contacts {
    margin-top: 120px;
  }
  .contacts__content {
    .title__name {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 25px;
      span {
        font-size: 50px;
      }
    }
    .content__description {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  .maps {
    width: 450px;
    height: 450px;
  }

  .mapConfig {
    width: 450px;
    height: 450px;
  }
}

@media (max-width: 550px) {
  .contacts__content {
    margin-bottom: 60px;
    .title__name {
      font-size: 32px;
      line-height: 35px;
      span {
        font-size: 40px;
      }
    }
    .content__description {
      font-size: 18px;
    }
  }
  .maps {
    width: 420px;
    height: 420px;
  }

  .mapConfig {
    width: 420px;
    height: 420px;
  }
  .contacts__list {
    li {
      margin-bottom: 30px;
      a {
        font-size: 19px;
      }
      .size__call {
        font-size: 23px;
      }
      p {
        font-size: 19px;
      }
      svg {
        margin-right: 15px;
        width: 32px;
        height: 32px;
      }
    }
  }
}
@media (max-width: 510px) {
  .contacts__content {
    .title__name {
      font-size: 27px;
    }
    .content__description {
      font-size: 16px;
    }
  }
  .contacts__list {
    a {
      font-size: 17px;
    }
    p {
      font-size: 17px;
    }
  }
  .maps {
    width: 360px;
    height: 360px;
  }

  .mapConfig {
    width: 360px;
    height: 360px;
  }
}
@media (max-width: 390px) {
  .contacts__content {
    // .title__name{
    //   font-size: 24px;
    //   line-height: 30px;
    //   span{
    //     font-size: 35px;
    //   }
    // }
    .contacts__list {
      li {
        svg {
          width: 29px;
          height: 29px;
        }
        a {
          font-size: 17px;
        }
        p {
          font-size: 17px;
        }
      }
    }
  }
  .maps {
    width: 330px;
    height: 330px;
  }

  .mapConfig {
    width: 330px;
    height: 330px;
  }
}
@media (max-width: 375px) {
  .contacts__content {
    .title__name {
      font-size: 25px;
      line-height: 30px;
      span {
        font-size: 37px;
      }
    }
    .contacts__list{
      li{
        svg{
          margin-right: 10px;
          width:25px ;
          height: 25px;
        }
        a{
          font-size: 15px; 
        }
      }
    }
  }
  .maps {
    width: 300px;
    height: 300px;
  }

  .mapConfig {
    width: 300px;
    height: 300px;
  }
}
